.inquire-div {
  background-color: black;
  padding-left: 16.66%;
}

.inquire-title {
  color: white;
  font-size: 1.7rem;
  font-weight: 800;
  margin-top: 2.7rem;
  margin-bottom: 1.7rem;
  font-family: "Pretendard-Bold";
}

.inquire-subtitle {
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  font-family: "Pretendard-Regular";
}

.inquire-info-div {
  display: inline-flex;
  margin-bottom: 2.7rem;
}

.inquire-info {
  color: white;
  font-size: 0.9rem;
  font-weight: 100;
  font-family: "Pretendard-Thin";
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: inline-flex;
  vertical-align: middle;
}

.inquire-icon {
  height: 0.9rem;
  margin-right: 15px;
  margin-top: 0.2rem;
}
